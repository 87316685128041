window.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('*[data-insert-year]').forEach((el) => {
    el.innerHTML = new Date().getFullYear();
  });

  document.querySelectorAll('*[data-insert-email]').forEach((el) => {
    // prettier-ignore
    el.innerHTML =
      '<' + 'a' + ' ' + 'h' + 'r' + 'e' + 'f' + '=' + "'" + '&' + '#' + '1' + '0' + '9' + ';' + 'a' + 'i' + '&' +
      '#' + '1' + '0' + '8' + ';' + 't' + 'o' + '&' + '#' + '5' + '8' + ';' + '%' + '6' + 'A' + 'a' + 'c' + '&' +
      '#' + '1' + '1' + '1' + ';' + 'b' + '&' + '#' + '6' + '4' + ';' + 'b' + 'i' + 'j' + 'a' + '&' + '#' + '1' +
      '1' + '0' + ';' + '&' + '#' + '1' + '0' + '5' + ';' + '&' + '#' + '3' + '7' + ';' + '2' + 'E' + 'c' + 'o' +
      '&' + '#' + '1' + '0' + '9' + ';' + "'" + '>' + 'j' + '&' + '#' + '9' + '7' + ';' + 'c' + 'o' + '&' + '#' +
      '9' + '8' + ';' + '&' + '#' + '6' + '4' + ';' + 'b' + 'i' + 'j' + 'a' + '&' + '#' + '1' + '1' + '0' + ';' +
      'i' + '&' + '#' + '4' + '6' + ';' + 'c' + '&' + '#' + '1' + '1' + '1' + ';' + 'm' + '<' + '/' + 'a' + '>';
  });
});
